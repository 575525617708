/*
#page-home {            

    width: 100%;

    height: 100vh;
    

    background-image: url("https://laffnetdev.website/img/website/BG_32568_20220810025247.png");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}
*/

/*background-color: #cccccc;*/ /* Used if the image is unavailable */

/*
background-image: url("https://laffnetdev.website/img/website/BG_32568_20220810025247.png");
background-position: center; 
background-repeat: no-repeat; 
background-size: cover; 
*/

.navbar.website-nabbar {
    padding: 0px;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding: 10px 15px;
}


#stage-name { font-size:90px; }
#title { font-size:60px; }
#tag-line { font-size:40px; }

.navbar-light .navbar-nav .active>.nav-link, 
.navbar-light .navbar-nav .nav-link.active, 
.navbar-light .navbar-nav .nav-link.show, 
.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .nav-link {
    color: #fff;
}
.navbar-light .navbar-toggler {
    color: #fff;
    border-color: #fff;
}






#page-about {          
    /*background-image: url("https://laffnetdev.website/img/website/BG_32568_20220810025247.png");*/ /* The image used */
    /* background-color: #2e2822; Used if the image is unavailable */
    height: 100vh;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

#page-gigs {
    /*background-image: url("https://laffnetdev.website/img/website/BG_32568_20220810025247.png");*/ /* The image used */
    /* background-color: #000000;  Used if the image is unavailable */
    height: 100vh;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}


#page-reels {
    /*background-image: url("https://laffnetdev.website/img/website/BG_32568_20220810025247.png");*/ /* The image used */
    /* background-color: #2e2822; Used if the image is unavailable */
    height: 100vh;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

.booking-form .form-control {
    background-color: #00000070;
    border: 2px solid #cccccc;
    color: #ffffff;
}

.booking-form .form-control:focus {
    border: 2px solid #cccccc;
}

.booking-form .form-control::placeholder {
    color: #e6e6e6;
    opacity: 1; /* Firefox */
}
  
.booking-form .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #e6e6e6;
}
  
.booking-form .form-control::-ms-input-placeholder { /* Microsoft Edge */
   color: #e6e6e6;
}



.modal-content {
    border: 1px solid #ffffff;
}

button.close {
    color: #ffffff;
    opacity: 1;
}


.modal-backdrop.show {
    opacity: 0.8 !important;
}