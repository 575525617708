
/*
@font-face {
	font-family: 'Nunito';
	src: local('Nunito'), url(./fonts/nunito/Nunito-Regular.ttf) format('truetype');
}
*/




@font-face {
	font-family: 'Neonderthaw';
	src: local('Neonderthaw'), url(./fonts/Neonderthaw/Neonderthaw-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Lato';
	src: local('Lato'), url(./fonts/Lato/Lato-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Lora';
	src: local('Lora'), url(./fonts/Lora/static/Lora-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'OpenSans';
	src: local('OpenSans'), url(./fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Merriweather';
	src: local('Merriweather'), url(./fonts/Merriweather/Merriweather-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Roboto';
	src: local('Roboto'), url(./fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'RobotoSlab';
	src: local('RobotoSlab'), url(./fonts/Roboto_Slab/static/RobotoSlab-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'FiraSans';
	src: local('FiraSans'), url(./fonts/Fira_Sans/FiraSans-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'PlayfairDisplay';
	src: local('PlayfairDisplay'), url(./fonts/Playfair_Display/static/PlayfairDisplay-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'JosefinSans';
	src: local('JosefinSans'), url(./fonts/Josefin_Sans/static/JosefinSans-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'JosefinSlab';
	src: local('JosefinSlab'), url(./fonts/Josefin_Slab/static/JosefinSlab-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url(./fonts/Montserrat/static/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'SourceSansPro';
	src: local('SourceSansPro'), url(./fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'NotoSans';
	src: local('NotoSans'), url(./fonts/Noto_Sans/NotoSans-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'NotoSerif';
	src: local('NotoSerif'), url(./fonts/Noto_Serif/NotoSerif-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Raleway';
	src: local('Raleway'), url(./fonts/Raleway/static/Raleway-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'LibreFranklin';
	src: local('LibreFranklin'), url(./fonts/Libre_Franklin/static/LibreFranklin-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'LibreBaskerville';
	src: local('LibreBaskerville'), url(./fonts/Libre_Baskerville/LibreBaskerville-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Karla';
	src: local('Karla'), url(./fonts/Karla/static/Karla-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Rubik';
	src: local('Rubik'), url(./fonts/Rubik/static/Rubik-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Oswald';
	src: local('Oswald'), url(./fonts/Oswald/static/Oswald-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Alegreya';
	src: local('Alegreya'), url(./fonts/Alegreya/static/Alegreya-Regular.ttf) format('truetype');
}




html {

    overflow-y: scroll;
    /*height: 100%;*/
	height: 100vh;

	
	/*background-image: url(/img/bg/70s.png);*/
	
	
	background-repeat: repeat;
	
	background-color: #a8b4ba;

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*
html, body, #root, .App, .App>div {
  height: 100%
}
*/

body {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  /*background-color: #eeeeee;*/
  background-color:transparent !important;
  /*overflow:auto;*/
}


/* Add this class to the page container on every page. This will prevent the footer from covering the page content at the bottom*/
.page-content {
	margin-bottom: 39px;
	background-color:transparent;
}

.page-main-container {
	margin-left: 80px;
	/*
	padding-left: 15px;
	padding-right: 15px;
	*/
}

/* Modal Styles */
.modal-xl {

	max-width: none;
	width: 100%;

	margin:0px;

	margin-top:60px;
	

}



.modal-xl .modal-content {
	border-radius:0px;
}

.modal-xl .modal-body {
	padding:0px;
}

/********************/

#search-box
{
	background-color:#eeeeee;
	outline: 0;
	border-width: 0 0 3px;
	border-color: #0050F5;
	border-radius:0;
}



#search-box:focus {
	border-width: 0 0 3px;
	border-color: #0050F5;
    -webkit-box-shadow: none;
    box-shadow: none;
}



.contact-tile {
	height:211px;
}


/*
.contact {
  border: 2px #000000 solid;
  background-color: #cccccc;
  margin-bottom:5px;
}
*/

.red {
  color:red;
}

.grayText {
	color: #8c8c8c; 
}

.whiteText {
	color: #FFFFFF; 
}

.bold {
	font-weight:bold;
}

.btn-gray-sm
{
	background-color: gray !important;
	color:#FFFFFF !important;
	padding: 3px 10px !important;
	font-size:15px !important;
	border-color:gray !important;
	border-radius:3px !important;
}

.btn-gray-sm:hover
{
	background-color:darkgray ;
	border-color:darkgray;
}

.btn-laff-sm
{
	background-color:#2BCB0A !important;
	color:#FFFFFF !important;
	padding: 3px 10px !important;
	font-size:15px !important;
	border-color:#2BCB0A !important;
	border-radius:3px !important;
}

.btn-laff-sm:hover
{
	background-color:#42a92d !important;
	border-color:#2BCB0A !important;
	color:#FFFFFF !important;
	
}

.btn-laff-sm-blue
{
	background-color:#0050F5 !important;
	color:#FFFFFF !important;
	padding: 3px 10px !important;
	font-size:15px !important;
	border-radius:3px !important;
}

.btn-laff-sm-blue:disabled {
	background-color:#ccc !important;
	border-color:#ccc !important;
}

.btn-laff-sm-yellow
{
	background-color:#FFDC00 !important;
	color:#000000 !important;
	padding: 3px 10px !important;
	font-size:15px !important;
	border-radius:3px !important;
}


/* checkbox button*/
.btn-secondary:not(:disabled):not(.disabled).active 
{
	color: #fff !important;
	background-color: #42a92d !important;
	border-color: #42a92d !important;
}

/* edit email template */
#externalSystemContainer
{
	background-color:darkgray;
	padding:5px;
}



.toggle.ios, .toggle-on.ios, .toggle-off.ios { border-radius: 20rem; }
.toggle.ios .toggle-handle { border-radius: 20rem; padding-left:30px; width:20px; }


/***********************************************/
/*  NavBar  */
/***********************************************/
/* navbar logo */
nav .navbar-brand img 
{
	
	height:40px;
	width:auto;
	
}


/* navbar background colour */
.navbar-dark
{
	background-color: #000000 !important; /* #d85426 */
	border-bottom: 4px solid #7B12BB !important;
}


/* navbar item */
.navbar-dark .navbar-nav .nav-link
{
	color:#ffffff !important;
	padding: 16px 12px;
}

/* hover - background color */
.navbar-dark .navbar-nav .nav-link:hover
{
	background-color: #0050F5 !important; /* A23F1C */
	
}

/* active - background color */
.navbar-dark .navbar-nav .active
{
	background-color: #A23F1C;
}

.navbar-dark .dropdown-menu
{
	background-color: #000000;
	color: #ffffff;
	border: none;
	
}

.navbar-nav .dropdown-menu a
{
	color: #ffffff !important;
}
.navbar-nav .dropdown-menu a:hover
{
	background-color: #0050F5 !important;
}

/* narbar dropdown transition */
.navbar-nav .dropdown-menu {
	display: block;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;
	padding-top:0;
	padding-bottom:0;
	border-radius: 0;
}

.navbar-nav .dropdown-menu.show {
    max-height: 500px;
	
}


/* Side Nav Bar */

.side-nav-link {
	color: #000;
}

.side-nav-link:hover {
	color: rgb(43, 42, 42);
}

/***********************************************/
/**************  Nav bar - End   **************/
/***********************************************/



/********************************************/
/*           Footer              */


.footer {


  	width:100%;
  	background-color:#000000;
  	color:#fff;
  	padding-top:10px;
  	padding-bottom:10px;
  	font-size:13px;


}



/**************** Import Accordion - START ******************/
.accordion-category {
	background-color: #0050F5;
    color: #fff;
    cursor: pointer;
	padding: 3px 5px;
	font-size:14px;
}

.accordion-header {
	background-color: #000; 
    color: #fff;
    cursor: pointer;
    padding: 3px 5px;
	border-bottom: 1px #ccc dotted;
	font-size:14px;
}

.accordion-body {
	background-color: #fff; 
    color: #000;
    cursor: pointer;
    padding: 3px 5px;
	border-bottom: 1px #ccc dotted;
	font-size:14px;
}

.accordion-body:hover {
	background-color: #ccc; 
}

#import-accordion {
	width: 100%;
	max-width:350px;

	position: absolute;
	/* right: 0px; */
	/* top: 100px; */
	z-index: 10;
	padding-bottom:45px;
}
/**************** Import Accordion - END ******************/

.laff-blue-txt
{
	color: #0050F5;
}

.laff-black-txt
{
	color: #000000;
}

.laff-gray-txt
{
	color: #bebebe;
}


.laff-blue
{
	background-color: #0050F5 !important;
	color:#ffffff !important;
}

.laff-black
{
	background-color: #000000 !important;
	color:#ffffff !important;
}

.laff-green
{
	background-color: #2BCB0A !important;
	color:#ffffff !important;
}

.laff-purple
{
	background-color: #7B12BB !important;
	color:#ffffff !important;
}

.laff-red
{
	background-color: #D42428 !important;
	color:#ffffff !important;
}


.laff-gray
{
	background-color: #a8b4ba !important;
	color:#ffffff !important;
	
}

.no-border {
	border:none;
}

.laff-modal {
	border: #0050F5 4px solid;
}

/*Stripo plugin*/
#stripoSettingsContainer ul li {

	
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	text-align: center;

} 



/********************/
/*stars*/
.stars-outer {
	display: inline-block;
	position: relative;
	font-family: FontAwesome;
	font-size:20px;
	color:#aaa;
  }
   
.stars-outer::before {
	content: "\f005 \f005 \f005 \f005 \f005"; /* \f005 if only outline */
}
   
.stars-inner {
	position: absolute;
	top: 0;
	left: 0;
	white-space: nowrap;
	overflow: hidden;
	width: 0;
	font-size:20px;
}
   
.stars-inner::before {
	content: "\f005 \f005 \f005 \f005 \f005";
	color: #f8ce0b;
}

/**************************/




.disabled {
	cursor: not-allowed;
}



/****************************/
/* Sales Funnel */
/***************************/

.sales-board {
	background-color:#ebecf0;
	min-height:200px;
	padding:10px 10px 30px 10px;
	border-radius:3px;
	margin-right:10px;

	width:275px;

	font-size:14px;
	
	position: relative;
}

.sales-board-controls {

	display:none;
	
	/*
	position: absolute;
	bottom: 10px;
	*/
}



.board-action-btn {
	color:#ebecf0;
    padding:0 10px 0 0;
    /*font-style: italic;*/
	font-size:13px;
	cursor:pointer;
}

.board-action-btn:hover {
	color: #000;
	text-decoration: underline;
}

.sales-card {

	position: relative;
	background-color:#fff;
	
	cursor:pointer;  /*cursor:grabbing;*/
	padding:10px; 
	margin-bottom: 10px;
	border-radius:3px;

	-webkit-box-shadow: 0px 1px 1px 0px rgba(133,133,133,0.7);
	-moz-box-shadow: 0px 1px 1px 0px rgba(133,133,133,0.7);
	box-shadow: 0px 1px 1px 0px rgba(133,133,133,0.7);

	font-size:12px;
}



.laff-bg {

	width: 100%;  
    height: 100%;
    background-image: 'url( ./../public/img/bg/50.png )'; /* Location of the image */
	
	
	background-repeat: repeat; 
	
	background-color: #a8b4ba; /* 838C91 Set a background color that will be displayed while the background image is loading */

}


/* Datepicker */

.date-input {

	width:120px;
	border-radius:3px;
	border: 1px solid #ced4da;
	padding: 3px 8px;

}

/* Secondary Menu below main navigation */
.secondary-menu {
	background-color: #838C91;
	padding-top: 15px;
	padding-bottom: 15px;
	margin-bottom: 15px;
}


/******* Select Email Contact *****/

#select-email-contacts .custom-control-label {
	font-weight: bold;
}



/*********************/
/*Office*/

#my-office-calendar .react-datepicker {
	width:100% !important;
	border-radius:0px !important;
	background-color: #0050F5 !important;
	border:none;
}

#my-office-calendar .react-datepicker__month-container {
	width:100% !important;
}

#my-office-calendar .react-datepicker__day-name, #my-office-calendar .react-datepicker__day {
	width:12% !important;
	color: #fff !important;
	
}



#my-office-calendar .react-datepicker__header {
	background-color: #0050F5 !important;
	border-bottom: 1px solid #fff !important;
	
}

#my-office-calendar .react-datepicker__current-month {
	color: #fff !important;
}

#my-office-calendar .react-datepicker__week {
	border-bottom: #fff 1px dashed;
}

#my-office-calendar .react-datepicker__year-read-view--selected-year {
	color: #fff !important;
}



#my-office-calendar .react-datepicker__day:hover {
	border-radius:0px !important;
	background-color: #7B12BB !important;
}

#my-office-calendar .react-datepicker__day--today {
	background-color: #2BCB0A !important;
}
/*
#my-office-calendar .react-datepicker__day--keyboard-selected {
	border-radius:0px !important;
	background-color: #7B12BB !important;
	color: #fff !important;
}
*/

#my-office-calendar .react-datepicker__day--selected {
	border-radius:0px !important;
	background-color: #7B12BB !important;
	color: #fff !important;
}

