
/***********************************************/
/*  Login  */
/***********************************************/

.form-control {
    background-color: #000 !important;
}

/* login */
.btn-laff
{
	background-color:#2BCB0A !important;
	color:#FFFFFF !important;
	padding: 6px 25px !important;
	
}
.btn-laff:hover
{
	background-color:#42a92d !important;
	color:#FFFFFF !important;
}

.btn-laff:disabled 
{
  background-color:#42a92d !important;
}

.btn-social-login
{
	border:1px solid #d2d2d2 !important;
	font-size:14px !important;
	font-weight:bold !important;
	
	width:110px !important;
	
    background-color: rgba(0, 0, 0, 0.3) !important;
  
    margin-left:2px !important;
    margin-right:2px !important;
}



.btn-social-login:hover
{
	border:1px solid #696969;
	background-color: rgba(0, 0, 0, 0.6);
}

#btn-facebook
{
	color:#3B5998;
}

#btn-facebook:hover
{
	color:#3B5998;
}


#btn-google
{
	color:#696969;
}

#btn-google:hover
{
	color:#696969;
}


.btn-social-login img
{
	height:14px;
	width:auto;
	margin-top:-4px;
}

.shaded-text
{
	color:#e4e3e3;
	text-decoration:none;
}

.shaded-text a, .shaded-text a:hover
{
	color:#ffffff;
	text-decoration:none;
}




/**********************************************/
/*  Login - End  */
/***********************************************/