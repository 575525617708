
/*
.modal-content {
    border: 1px solid #0050F5 !important;
}
*/


.form-control {
    background-color: #000 !important;
}

.modal-title {
    font-size: 32px;
}

.modal-body {
    font-size: 18px;
}


.modal-body .btn {
    font-size: 18px !important;
}