
.page-link {
    color: #fff;
    background-color: #000;
}

.page-item.disabled .page-link {
    background-color: #000;
}


.page-item.active .page-link {
    background-color: #0050F5;
    border-color: #0050F5;
}

.nav-tabs .nav-link.active{
    color: #ffffff;
    background-color: #0050F5;
}

link.active {
    color: #ffffff;
    background-color: #0050F5;
}

a:not([href]):not([tabindex]) {
    color: #fff;
}

a:not([href]):not([tabindex]):hover {
    color: #fff;
}


.rbt-token {
    background-color: #0050F5 !important;
    color: #ffffff !important;
}

.rbt-input-multi .rbt-input-main {
    color: #ffffff !important;
}